export const SITE_SETTINGS = {
    isCMS: true,
    isDatabaseEnable: true,
    isTelegramEnabled: false,
    isRentalFixedPrice: true,
    isSMSEnabled: false,
    OTP: false,
    siteName: 'Zing Cabs',
    phoneNo: '9902350915',
    whatsapp: '+919902350915',
    websiteAddress: 'www.zingcabs.com',
    smsKey: 'otqQWUZfsICzOXl4Bmd5HAb0TjrkEg9v6hKVSuLncFY3Gp18wi0PjgzXevTYoLJiWdHnNuUBma37fMA5',
    contentAPI: "https://cdn.contentful.com/spaces/brr64dmu4x7k/environments/master/entries?access_token=ZGqVnk_hMQpvltuisuLov_WYlzskHeELc9GAW1XpPoc",
    enquiryEmail: 'cabszing@gmail.com',
    emailAPI: 'https://us-central1-calvincare-emailservice.cloudfunctions.net/sendEmail',
    smsAPI: 'https://us-central1-smsapi-service.cloudfunctions.net/sendSms/api/v1/sendsms',
    quickEnquiry: 'https://us-central1-smsapi-service.cloudfunctions.net/quickEnquiry',
    telegramAPI: 'https://us-central1-telegram-api-service.cloudfunctions.net/telegramAPI/api/v1/telegram',
    siteInfo: `Toll Gate, Permit, Hill Station Charges Extra`,
    adminEmailSubject: 'Website Enquiry Email From',
    customerEmailSubject: 'Booking Confirmation ',
    bannerColor: "#fff",
    appPassword: 'wzwuhcjbqlqkapzk',
    appEmail: 'calvincareinfotech.emailservice@gmail.com',
    tKey: '',
    tGroup: '',
    OTPApi: 'https://us-central1-smsapi-service.cloudfunctions.net/otp/api/v1/sendsms',
    logoURL:'https://www.zingcabs.com/assets/logo.png',
    address:`Opp Venugopalaswamy Temple, 1st Floor, No 5 K No 297/A/27,
    1st Main Road, KR Puram, Bengaluru, Bengaluru Urban,
    Karnataka, 560036`
}