export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAhgXPG38znlq1tugfKPNmtG9KhqPupL_U",
    authDomain: "southindiacabs-in.firebaseapp.com",
    databaseURL: "https://southindiacabs-in-default-rtdb.firebaseio.com",
    projectId: "southindiacabs-in",
    storageBucket: "southindiacabs-in.appspot.com",
    messagingSenderId: "357591181867",
    appId: "1:357591181867:web:f28745178fe024c7ec986e"
  }
};
