<section class="banner pt-5 pb-0 overflow-hidden" style="background-image:url(assets/images/testimonial.png);">
    <div class="container">
        <div class="banner-in">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-4">
                    <div class="banner-content text-lg-start text-center">
                        <h4 class="theme mb-0">Explore The South India</h4>
                        <h1>Start Planning Your Dream Trip Today!</h1>
                        <p class="mb-4">We provide one way and Round trip outstation cab accross Karnataka, Tamil Nadu,
                            Pondicherry, Kerala & Andhra.
                        </p>

                        <app-outstation></app-outstation>
                    </div>
                </div>
                <div class="col-lg-6 mb-4">
                    <div class="banner-image">
                        <img src="assets/images/travel.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-us pb-6 pt-6" style="background-image:url(assets/images/shape4.png); background-position:center;">
    <div class="container">
        <div class="section-title mb-6 w-50 mx-auto text-center">
            <h4 class="mb-1 theme1">3 Step of The Perfect Tour</h4>
            <h2 class="mb-1">Find <span class="theme">Travel Perfection</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
            </p>
        </div>

        <div class="why-us">
            <div class="why-us-box">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <i class="icon-flag theme"></i>
                                </div>
                                <h4><a href="about.html">Tell Us What You want To Do</a></h4>
                                <p class="mb-0">We offer drop taxi, Airport pickup & drop, Hourly Rental & Tour package
                                    servicers.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <i class="icon-location-pin theme"></i>
                                </div>
                                <h4><a href="about.html">Share Your Travel Locations</a></h4>
                                <p class="mb-0">Get free travel estimation using for booking form. </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <i class="icon-directions theme"></i>
                                </div>
                                <h4><a href="about.html">Share Your Travel Preference</a></h4>
                                <p class="mb-0">Just book & relax, We'll take care your jouney plan for you</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
                        <div class="why-us-item text-center p-4 py-5 border rounded bg-white">
                            <div class="why-us-content">
                                <div class="why-us-icon">
                                    <i class="icon-compass theme"></i>
                                </div>
                                <h4><a href="about.html">We are 100% Trusted Tour Agency</a></h4>
                                <p class="mb-0">The company was established in the year 2016. {{settings.siteName}} is
                                    an
                                    government registered company providing outstation taxi service with oneway fare.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="white-overlay"></div>
</section>


<section class="trending pb-5 pt-0">
    <div class="container">
        <div class="section-title mb-6 w-50 mx-auto text-center">
            <h4 class="mb-1 theme1">Top Destinations</h4>
            <h2 class="mb-1">Explore <span class="theme">Top Destinations</span></h2>

        </div>
        <div class="row align-items-center">
            <div class="col-lg-5 mb-4">

                <div class="trend-item1">
                    <div class="trend-image position-relative rounded">
                        <img src="assets/bangalore.jpg" alt="image">
                        <div
                            class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                            <div class="trend-content-title">
                                <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Karanataka</a>
                                </h5>
                                <h3 class="mb-0 white">Bangalore</h3>
                            </div>
                            <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                        </div>
                        <div class="color-overlay"></div>
                    </div>
                </div>

        
            </div>
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">

                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/chennai.webp" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a></h5>
                                        <h3 class="mb-0 white">Chennai</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/madurai.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Madurai</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/pondicherry.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Pondicherry </h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-4">
                        <div class="trend-item1">
                            <div class="trend-image position-relative rounded">
                                <img src="assets/Coimbatore.jpg" alt="image">
                                <div
                                    class="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                                    <div class="trend-content-title">
                                        <h5 class="mb-0"><a href="tour-grid.html" class="theme1">Tamil Nadu</a>
                                        </h5>
                                        <h3 class="mb-0 white">Coimbatore</h3>
                                    </div>
                                    <span class="white bg-theme p-1 px-2 rounded">Book Now</span>
                                </div>
                                <div class="color-overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-us pt-0" style="background-image:url(assets/images/bg/bg-trans.png);">
    <div class="container">
        <div class="about-image-box">
            <div class="row d-flex align-items-center justify-content-between">
                <div class="col-lg-6 mb-4 pe-4">
                    <div class="about-image overflow-hidden">
                        <img src="assets/images/travel1.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6 mb-4 ps-4">
                    <div class="about-content text-center text-lg-start mb-4">
                        <h4 class="theme d-inline-block mb-0">Get To Know Us</h4>
                        <h2 class="border-b mb-2 pb-1">Explore All Tour of the world with us.</h2>
                        <p class="border-b mb-2 pb-2">{{settings.siteName}} is leading outstation taxi provider in Tamil
                            Nadu.
                            We strive to make the taxi booking an easy and comfortable experience through our online cab
                            booking service. Wherever you travel in Tamil Nadu, Kerala, Bangalore & Pondicherry, we have
                            got a cab for you. You can choose from a small hatchback car or comfortable sedan (Dzire,
                            Etios) car or an suv (Innova, Xylo), if you are big family or even Tempo-traveller if you
                            are large travel group.
                        </p>
                        <div class="about-listing">
                            <ul class="d-flex justify-content-between">
                                <li><i class="icon-location-pin theme"></i> Quality Drivers</li>
                                <li><i class="icon-briefcase theme"></i> Friendly Price</li>
                                <li><i class="icon-folder theme"></i> Reliable Tour Package</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>


<section class="trending pb-0">
    <div class="container">
        <div class="row align-items-center justify-content-between mb-6 ">
            <div class="col-lg-7">
                <div class="section-title text-center text-lg-start">
                    <h4 class="mb-1 theme1">Top Pick</h4>
                    <h2 class="mb-1">Best <span class="theme">Tour Packages</span></h2>
                    <p>{{settings.siteName}} presents each intercity (outstation) taxi booking
                        services as well as intercity (nearby) cab services and coimbatore airport taxi.
                    </p>
                </div>
            </div>
            <div class="col-lg-5">
            </div>
        </div>
        <div class="trend-box">
            <div class="row item-slider">
                <div *ngFor="let city of places" class="col-lg-4 col-md-6 col-sm-6 mb-4">
                    <div class="trend-item rounded box-shadow">
                        <div class="trend-image position-relative">
                            <img src="{{city.image}}" alt="image" class="">
                        </div>
                        <div class="trend-content p-4 pt-5 position-relative">
                            <h3 class="mb-1"><a href="tour-grid.html">
                                {{city.cityName | uppercase}} DROP TAXI
                                </a></h3>


                            <div class="entry-meta">
                                <div class="entry-author d-flex align-items-center">
                                    
                                    <ul class="about-list">
                                        <ng-container *ngFor="let subCity of subCitys">
                                            <li *ngIf="city !== subCity">
                                                <a
                                                    routerLink="/city-detail/{{city.cityName| lowercase}}-to-{{subCity| lowercase}}-droptaxi-cabs">
                                                    ✓  {{city.cityName}} to {{subCity}} taxi
                                                </a>
                                            </li>
                                        </ng-container>
    
    
    
                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section *ngIf="places" class="trending pb-0 pt-6" style="background-image: url(assets/images/shape2.png);">
    <div class="container">
        <div class="section-title mb-6 w-75 mx-auto text-center">
            <h2 class="mb-1"><span>Top Drop Taxi Routes</span></h2>
            <p> Tamil Nadu Top drop taxi routes.
            </p>
        </div>
        <div class="trend-box">
            <div class="row">
                <div  *ngFor="let city of places"  class="col-lg-4 mb-4">
                    <div class="trend-item1 rounded box-shadow mb-4">
                        <div routerLink="/city/{{city.cityName}}" class="trend-image position-relative">
                            <img src="{{city.image}}" alt="image" class="">
                            <div class="trend-content1 p-4">
                                <h3 class="mb-1 white"><a href="tour-grid.html" class="white">{{city.cityName}} Drop
                                        Taxi</a>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="discount-action pt-6"
    style="background-image:url(assets/images/section-bg1.png); background-position:center;">
    <div class="section-shape section-shape1 top-inherit bottom-0"
        style="background-image: url(assets/images/shape8.png);">
    </div>
    <div class="container">
        <div class="call-banner rounded pt-10 pb-14">
            <div class="call-banner-inner w-75 mx-auto text-center px-5">
                <div class="trend-content-main">
                    <div class="trend-content mb-5 pb-2 px-5">
                        <h5 class="mb-1 theme">Love Where Your're Going</h5>
                        <h2><a href="detail-fullwidth.html">Explore Your Life, <span class="theme1"> Travel Where
                                    You Want!</span></a></h2>
                        <p>All type of cars / Maxi cabs available. Experienced drivers. 20 years of experience. 24x7 cab
                            service we provide all over Tamil Nadu and Bangalore.</p>
                        <a class="btn btn-default" href="tel:{{settings.phoneNo}}"
                            style="background-color: #029e9d;">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="white-overlay"></div>
</section>